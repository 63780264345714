@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  background-color: black;
  color: white;
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

* {
  margin: 0;
}

.logo-container {
  width: 150px;
  margin: auto;
  padding-top: 15px;
  display: flex;
  flex-direction:row;
}

.logo {
  width: 150px;
  margin: auto;
}

.left {
  font-size: 1.2em;
  height: 100vh;
  box-sizing: border-box;
  padding: 100px;
  padding-top: 150px;
}

.button-container {
  margin: auto;
  width: 250px;
}

.info {
  font-size: 0.8em;
  color: lightgray;
  margin-top: 10px;
}

#primaryButton {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: bold;
  border: #1DB954 1px solid;
  background-color: transparent;
  border-radius: 100px;
  width: 238px;
  padding: 5px;
  font-size: 0.9em;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.2s ease-out;
}

#primaryButton:hover {
  background-color: #1DB954;
}

.me {
  color: grey;
}
.me:hover {
  color: #1DB954;
}
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.mobile {
  display: none;
}

.beta {
  font-weight: bold;
  background-color: #f59b23;
  display: inline-block;
  padding: 5px;
  border-radius: 5px;
  height: 20px;
}

@media only screen and (max-width: 800px) {
  #get-in-room {
    max-width: 300px;
    margin: 50px auto;
  }

  .mobile {
    margin-top: 15px;
    display: block;
  }
}
